body {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-textarea {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    padding: 0.4em;
    background-color: white;
    font-size: calc(12px + 1vmin);
    color: #050506;
}

.App-header {
  background-color: #25262a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-left {
  text-align: left;
}

.App-link {
  color: #61D790;
  
}

.button {
  background-color: #7ccb80; /* Green background color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding for better spacing */
  border: none; /* Remove default border */
  border-radius: 5px; /* Add a rounded border */
  font-size:  calc(8px  + 1vmin); /* Adjust font size */
  cursor: pointer; /* Add pointer cursor for interactivity */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

button:hover {
  background-color: #7ccb80; /* Darker green on hover */
}
